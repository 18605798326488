@mixin common-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
@mixin common-flex-spacebetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@mixin common-flex-baseline {
    display: flex;
    align-items: baseline;
    justify-content: center;
}
@mixin align-item-center {
    display: flex;
    align-items: center;
}
@mixin common-flex-jc {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin flex-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
@mixin common-flex-jsb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@mixin desktop-lg {
    @media (max-width: 1399.98px) {
        @content;
    }
}
@mixin desktop {
    @media (max-width: 1199.98px) {
        @content;
    }
}
@mixin tablet {
    @media (max-width: 991.98px) {
        @content;
    }
}
@mixin mobile {
    @media (max-width: 767.98px) {
        @content;
    }
}
@mixin mobile-sm {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin font-smoothing($value: on) {
    @if $value==on {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    } @else {
      -webkit-font-smoothing: subpixel-antialiased;
      -moz-osx-font-smoothing: auto;
    }
  }

@mixin h($size) {
    margin: 0;
    line-height: 1.4;
    font-weight: 600;
    @if $size==1 {
      @include font-smoothing(on);
      font-size: 38px;      
    }
  
    @if $size==2 {
      @include font-smoothing(on);
      font-size: 33px;
    }
  
    @if $size==3 {
      font-size: 24px;
      margin-bottom: 0;
      font-weight: 500;
    }
    @if $size==4 {
      font-size: 18px;
      line-height: 1.4;
    }
    @if $size==5 {
      font-size: 22px;
      line-height: 1.4;
    }
    
    
  }

  @mixin position-relative{
    position: relative;
    width: 100%;
    float: left;
  }

  .mb-30{
    margin-bottom: 30px !important;
  }