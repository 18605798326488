.blue-link{
    font-size: 13.21px;
    font-weight: 600;
    color: $secondary-color;
    &:hover{
        opacity: .8;
    }
}
.btn-primary{
    background-color: $primary-color;
    border-radius: 4px;
    font-size: 16px;
    color: $color-white;
    border: solid 1px $primary-color !important;
    border: none;
    padding: 10px 40px;
    &:hover{
        background-color: $primary-color;
        opacity: .8;
    }
    &:focus{
        background-color: $primary-color;
        opacity: .8;
        box-shadow: none;
    }
    &:focus-within{
        background-color: $primary-color !important;
        opacity: .8;
        box-shadow: none;
    }
    
    &:active{
        background-color: $primary-color !important;
        opacity: .8;
        box-shadow: none;
    }
    &:disabled{
        background-color: $primary-color !important;
        opacity: .8;
        box-shadow: none;
        min-width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}
.btn-link{
    padding: 10px 40px;
    color: #444;
    font-size: 16px;
    text-decoration: none;
    &:hover{
        opacity: .8;
        color: #444;
    }
    &:focus{
        opacity: .8;
        box-shadow: none;
    }
}
.btn-outline-secondary{
    background-color: $color-white;
    border-radius: 4px;
    font-size: 16px;
    color: $color-black;
    border: solid 1px $color-black !important;
    border: none;
    padding: 10px 40px;
    &:hover{
        background-color: $color-white;
        opacity: .8;
        color: $color-black;
    }
    &:focus{
        background-color: $color-white;
        opacity: .8;
        box-shadow: none;
        color: $color-black;
    }
}
.remove-link{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    color: #FF4949;
    font-weight: 400;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    &:hover{
        background-color: transparent;
        color: #FF4949;
        opacity: .8;
    }
    &:focus{
        box-shadow: none;
        border: none;
        outline: none;
        background-color: transparent;
        color: #FF4949;
    }
    &:active{
        box-shadow: none;
        border: none;
        outline: none;
        background-color: transparent;
        color: #FF4949;
    }
}
.custom-textarea{
    height: 82px;
    resize: vertical;
}
.btn-blue-solid{
    background-color: $secondary-color;
    font-size: 11px;
    color: $color-white;
    border: none;
    &:hover{
        background-color: $secondary-color;
        opacity: .8;
    }
    &:focus{
        background-color: $secondary-color;
        opacity: .8;
    }
}
.btn-blue-outline{
    background-color:$color-white;
    font-size: 11px;
    color:  $secondary-color;
    border: solid 1px $secondary-color;
    &:hover{
        background-color: $color-white;
        color:  $secondary-color;
        border-color: $secondary-color;
        opacity: .8;
    }
    &:focus{
        background-color: $color-white;
        opacity: .8;
        color:  $secondary-color !important;
    }
    &:focus-within{
        background-color: $color-white;
        opacity: .8;
        color:  $secondary-color !important;
    }
}
.select-link{
    background: transparent;
    padding: 0;
    color: $secondary-color;
    border: none;
    &:hover,
    &:focus,
    &:active,
    &:focus-within{
        background-color: transparent !important;
        color: $secondary-color !important;
        opacity: .8;
    }
}