.main-footer{
    @include position-relative;
    background: $light-black;
    padding: 30px 0 66px;
    @include tablet{
        padding: 0 0 40px;
    }
    .lula-logo{
        margin: 25px 0 50px;
        max-width: 85px;
        @include mobile{
            max-width: 50px;
        }
    }
    img{
        max-width: 120px;
        display: block;
        margin-bottom: 30px;
    }
    h5{
        font-size: 15px;
        color: $color-white;
        text-transform: uppercase;
        position: relative;
        padding-bottom: 8px;
        margin-bottom: 7px;
        @include tablet{
            font-size: 14px;
        }
        @include tablet{
            margin-top: 30px;
        }
        @include mobile-sm{
            font-size: 14px;
        }
        &::before{
            content: "";
            position: absolute;
            width: 40px;
            height: 1px;
            background: $primary-color;
            bottom: 0;
            left: 0;
        }
    }
    .footer-nav-link{
        font-size: 13px;
        color: $color-disabled;
        @include desktop{
            font-size: 14px;
        }
        &:hover{
            color: $color-white;
        }
    }
    ul{
        li{
            &:not(:last-of-type){
                margin-bottom: 5px;
            }
        }
    }
    .social{
        @include align-item-center;
        margin-top: 10px;
        a{
            margin-right: 30px;
        }
    }
    hr{
        color: $color-white;
    }
    .make-a-wish{
        max-width: unset;
        margin: 30px 0;
        cursor: pointer;
    }
    .call-wrapper{
        width: 100%;
        border: 1px solid $color-white;
        @include common-flex;
        font-size: 18px;
        text-transform: uppercase;
        color: $color-white;
        padding: 7px;
        @include desktop{
            font-size: 15px;
        }
        a{
            color: $color-white;
            &:hover{
                color: $primary-color;
            }
        }
    }
}
.footer-tail{
    @include position-relative;
    background: $color-dark;
    padding: 15px 0;
    p{
        font-size: 11px;
        color: $color-disabled;
        text-align: center;
        text-transform: uppercase;
        margin: 0;
    }
}
