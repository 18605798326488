.signup-outer {
    @include position-relative;
    padding: 35px 0;

    h2 {
        margin-bottom: 20px;
    }

    h3 {
        padding-bottom: 5px;
        font-weight: 400;
    }

    h4 {
        color: #283A5D;
    }

    .mn-subheading {
        margin-top: 15px;
    }
}

.header-divider {
    width: 97px;
    height: 2px;
    background-color: #48C898;
}

.form-label {
    font-size: 14px;
    color: rgba(40, 58, 93, 0.7);

    i {
        margin-left: 5px;

        svg {
            ellipse {
                fill: $secondary-color;
            }
        }
    }
}

.form-control {
    height: 42px;
    font-size: 15px;

    &:focus {
        border-color: $secondary-color;
        box-shadow: 0 0 0 0.25rem #48C89810;
    }

    &.date-picker {
        background-image: url(../../../public/Images/calendar.svg);
        background-repeat: no-repeat;
        background-position: right 15px center;
    }
}

.form-control-select {
    .css-1s2u09g-control {
        height: 42px;
        font-size: 15px;
        border-radius: 0.375rem;

        &:hover {
            border-color: $secondary-color;
        }
    }

    .css-1pahdxg-control {
        border-color: $secondary-color;
        box-shadow: 0 0 0 0.25rem #48C89810;
        height: 42px;
        font-size: 15px;

        &:hover {
            border-color: $secondary-color;
        }
    }
}

.form-check {
    position: relative;

    .form-check-input {
        width: 20px;
        height: 20px;
        position: relative;
        top: 5px;

        &:checked {
            background-color: $secondary-color;
            border-color: $secondary-color;
        }

        &:focus {
            box-shadow: 0 0 0 0.25rem #48C89810;
        }
    }

    .form-check-label {
        margin-top: 3px;
        font-size: 14px;
        color: #283A5D;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        padding: 6px 10px 6px 25px;
    }

    .after-hour-rating {
        position: unset !important;
        padding: 6px 10px 6px 4px;
    }
    .af-label{
      font-weight: 600;
      color: red;
      margin-left: 5px;
      font-size: 14px;
    }
}

.mb-eraser {
    margin-bottom: 0px !important;
}

.action-wrapper {
    @include position-relative;
    @include common-flex-jc;
    flex-direction: column;
    margin: 50px 0;
    margin: 50px 0;
    &.provider-state-not-listed-page{
        margin: 40px 0;
    }
}

.form-control {
    height: 42px;
    font-size: 15px;

    &:focus {
        border-color: $secondary-color;
        box-shadow: 0 0 0 0.25rem #48C89810;
    }

    &.filter-by {
        background-image: url(../../../public/Images/search.svg);
        background-repeat: no-repeat;
        background-position: left 15px center;
        padding-left: 40px;
    }
}

.global-warnings {
    @include position-relative;
    @include common-flex-jsb;
    background: rgba(255, 142, 0, 0.15);
    border-radius: 5px;
    padding: 10px 15px;

    @include tablet {
        flex-direction: column;
    }

    p {
        margin: 0;
        font-size: 15px;
    }

    &--data {
        display: flex;
        gap: 0 10px;
        @include position-relative;
    }

    &--stat {
        @include position-relative;
        text-align: right;
    }
    .state-not-listed-alert{
        color: #FF4949;
        font-weight: 600
    }
}

.service-city {
    @include position-relative;
    margin: 25px 0;

    .city-item {
        @include position-relative;
        padding-bottom: 15px;
        display: flex;
        height: 100%;

        .custom-check {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            cursor: pointer;
            z-index: 1;

        }

        .city-item--inner {
            @include position-relative;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 9px;
            padding: 10px 10px;
            display: flex;
            align-items: center;
            gap: 0 10px;
        }

        .city-item--inner .check-box {
            width: 15px;
            height: 15px;
            border-radius: 4px;
            border: solid 1px #B3B3B3;
            flex-shrink: 0;
        }

        .custom-check:checked+.city-item--inner {
            background: #DAF4EA;
            border-color: #48C898;
        }

        .custom-check:checked+.city-item--inner .check-box {
            border-color: #48C898;
            background: #48C898 url(../../../public/Images/tick.svg) no-repeat center;

        }

        .city-item--inner .city-name {
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;
        }

        .city-item--inner .city-name span {
            font-size: 10px;
        }

        .custom-check:checked+.city-item--inner .city-name {
            font-weight: 600;
        }

        &.disabled {
            border-color: #b4b4b4;
            cursor: no-drop;

            .city-name {
                color: #b4b4b4;
            }

            .check-box {
                border-color: #b4b4b4;
            }
        }
    }

}

.service-summary {
    @include position-relative;
    @include common-flex;
    justify-content: flex-end;

    &--item {
        width: 54px;
        height: 54px;
        border-radius: 50%;
        background-color: #F4F7FF;
        font-size: 18px;
        line-height: 1;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.h4-black {
    color: #000 !important;
    margin: 0;
}

.licence-number-outer {
    @include common-flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .form-control {
        width: 245px;
    }

    strong {
        position: relative;
    }

    .form-check {
        position: relative;
    }
}

.form-control--textarea {
    height: 150px !important;
    resize: vertical;
}

.global-divider {
    border-color: rgba(40, 58, 93, .502);
    margin: 30px 0 !important;
    padding: 0;
}

.review-input {
    @include position-relative;
    margin-bottom: 10px;

    .form-control {
        padding-right: 80px;
    }
}

.custom-mr-5 {
    margin-right: 5px;
}

.textarea-lg {
    height: 120px;
}

.competency-heading {
    font-size: 20px;
    margin: 25px 0 0 0 !important;
    font-weight: bold !important;
    padding: 5px 0 15px 0;
    border-bottom: solid 1px #d5d5d5;
}

.sub-head-city {
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 15px;

    @media(max-width:565.98px) {
        flex-direction: column;
    }

    h5 {
        margin: 0;
    }

    p {
        margin: 0;
        font-size: 16px;
    }
}

.font-600 {
    font-weight: 600 !important;
}


.competency-test {
    @include position-relative;
    margin: 25px 0;

    .competency-test-item {
        @include position-relative;
        padding-bottom: 15px;
        display: flex;
        height: 100%;

        .custom-check {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            margin: 0;
            cursor: pointer;
            z-index: 1;
        }

        .form-check {
            width: 100%;
            padding-left: 0;
        }

        .competency-test--inner {
            @include position-relative;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 9px;
            padding: 10px 10px;
            display: flex;
            align-items: center;
            gap: 0 10px;
        }

        .competency-test--inner .check-box {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: solid 1px #B3B3B3;
            flex-shrink: 0;
        }

        .custom-check:checked+.competency-test--inner {
            background: #1D66FF10;
            border-color: $secondary-color;
        }

        .custom-check:checked+.competency-test--inner .check-box {
            border-color: $secondary-color;
            background: $secondary-color;
            position: relative;

            &::after {
                position: absolute;
                content: '';
                width: 8px;
                height: 8px;
                background-color: $color-white;
                border-radius: 50%;
                top: 5px;
                left: 5px;

            }

        }

        .competency-test--inner .competency-test-name {
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;
        }

        .competency-test--inner .competency-test-name span {
            font-size: 10px;
        }

        .custom-check:checked+.competency-test--inner .competency-test-name {
            // font-weight: 600;
        }

        &.disabled {
            border-color: #b4b4b4;
            cursor: no-drop;

            .competency-test-name {
                color: #b4b4b4;
            }

            .check-box {
                border-color: #b4b4b4;
            }
        }
    }

}

.acknowledge-box {
    @include position-relative;
    background: rgba(255, 73, 73, 0.1);
    border-left: 4px solid #FF4949;
    padding: 15px;
    margin-top: 25px;

    p {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #283A5D;
        margin: 0 0 10px 0;

        span {
            color: #FF4949;
        }
    }

    .mb-3 {
        margin-bottom: 0 !important;
    }

    .form-check-input {
        width: 20px;
        height: 20px;
    }

    .form-label {
        font-size: 18px;
    }
}

.uploaded-docs--card {
    position: relative;
    width: 100%;
    background: #F4F7FF;
    border: 1px solid rgba(40, 58, 93, 0.1);
    border-radius: 10px;
    display: flex;
    gap: 15px;
    padding: 11px 10px;
    margin-bottom: 15px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &.upload-document {
        padding: 30px 11px;
    }

    &.drop-here {
        padding: 30px 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $secondary-color;
        border: dashed 1px $secondary-color;
    }
    .close-button {
        border-style: none;
        background: transparent;
        position: absolute;
        top: 10px;
        right: 10px;
        float: right;

        &:hover {
            opacity: .8;
        }
    }

    .uploaded--image {
        width: 40%;
        height: 200px;
        border: 1px solid rgba(40, 58, 93, 0.1);
        border-radius: 5px;
        overflow: hidden;
        flex-shrink: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            cursor: pointer;
        }
    }

    .uploaded--data {
        width: calc(100% - 40%);

        .date-picker--pop .react-datepicker__day--today {
            color: $color-white !important;
        }

        h4 {
            font-size: 16px;
            font-weight: 600;
            margin-top: 5px;
            margin-bottom: 5px;
            word-wrap: break-word;

            @media(max-width:1599.98px) {
                padding-right: 25px;
                font-size: 14px;
            }
        }

        .content.expired {
            color: $error-color;
        }

        .content {
            font-size: 12px;
            width: 100%;
            padding: 3px 0 0 0;

            button {
                border-style: none;
                background: transparent;
                font-size: 12px;
                font-weight: 600;
                color: $secondary-color;
                padding: 0px;
                margin: 0px;

                &:hover {
                    opacity: .8;
                    text-decoration: none;
                }
            }

        }

        .action-wrap {
            font-size: 12px;
            width: 100%;
            padding: 5px 0 0 0;
            display: flex;
            gap: 5px;
            margin-top: 4px;

            .blue-link {
                border-style: none;
                background: transparent;
                font-size: 12px;
                color: $secondary-color;
                font-weight: 600;
                padding-left: 0px;

                &:hover {
                    opacity: .8;
                    text-decoration: none;
                }
            }

            .orange-link {
                border-style: none;
                background: transparent;
                font-size: 12px;
                color: $warning-color;
                font-weight: 600;

                &:hover {
                    opacity: .8;
                    text-decoration: none;
                }
            }

            .red-link {
                border-style: none;
                background: transparent;
                font-size: 12px;
                color: $secondary-color;
                font-weight: 600;

                &:hover {
                    opacity: .8;
                    text-decoration: none;
                }
            }
        }
    }

    &.upload-document {
        justify-content: center;
        gap: 5px !important;

        p {
            margin: 0;
            padding: 0;

            span {
                color: $secondary-color;
            }
        }

        .upload-input {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            appearance: none;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }

        &:hover {
            opacity: .8;
        }
    }

    &.expired {
        background: #FFF0F0;
        border-color: #FFC2C1;
    }
}

.image-upload-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.pro-uploaded--image{
    position: relative;
    width: 300px;
    height: 300px;
    background: #F4F7FF;
    padding: 11px;
    border-radius: 6px;
    margin-top: 25px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .pro-close-btn{
        position: absolute;
        top: -10px;
        right: -11px;
        border-style: none;
        background: transparent;
        &:hover{
            opacity: .8;
        }
    }
}

.city-consent{
    display: flex;
    gap: 10px;
}
