.date-picker--pop{
    .react-datepicker{
        border-color: $color-white;
        box-shadow: 0 0 5px 3px  #d5d5d580;
        &__triangle{
            &:before{
                border-bottom-color: $outline-grey !important;
                display: none;
            }
            &::after{
                border-bottom-color: $outline-grey !important;
                display: none;
            }
        }
        &__header{
            border-bottom: none;
            background-color: $color-white;
            &__dropdown--select{
                padding: 5px 0;
            }
        }
        &__day-name{
            font-weight: 600;
            color: $grey-10;
        }
        &__day--today{
            background-color: $secondary-color;
        }
        &__day--selected{
            background-color: $secondary-color;
        }
        &__current-month{
            color: $secondary-color;
            display: none;
        }
        &__day--keyboard-selected{
            color: $secondary-color;
        }
        &__month-select,
        &__year-select{
            border-style: none;
            border: solid 1px #d5d5d5;
            padding: 4px;
            border-radius: 4px;
        }
        &__navigation-icon--previous{
            right: 1px;
            top: 12px;
        }
        &__navigation-icon--next{
            left: 1px;
            top: 12px;
        }
        &__day--keyboard-selected{
            background-color: $secondary-color;
            color: $color-white;
        }
    }
}