.intro-section{
    h2{
        margin: 0;
    }
    p{
        font-size: 20px;
        font-weight: 600;
        margin:0;
    }
}

.sla-intro-section h4{
    color: #000;
    margin-bottom: 20px;
}

.sla-list{
    @include position-relative;
    li{
        @include position-relative;
        font-size: 16px;
        list-style-position: inside;
        margin-bottom: 12px;
        padding-left: 25px;
        &:after{
            position: absolute;
            content: '';
            width:4px;
            height: 4px;
            background-color: $color-black;
            border-radius: 50%;
            top:10px;
            left: 10px;
        }
    }
}
.form-single-box{
    @include common-flex;
    justify-content: flex-start;
    .form-check-input{
        width: 20px;
        height: 20px;
        border-color: #283A5D;
        &:checked{
            background-color: $primary-color;
            border-color: $primary-color;
        }
        &:focus{
            box-shadow: 0 0 0 0.25rem #48C89810;
        }
    }
    .form-label{
        margin: 0;
        color: $color-black;
        font-size: 16px;
        a{
            color: var(--bs-link-color) !important;
        }
    }
    .text-danger{
        margin-left: 36px;
        padding: 2px 20px;
        background: red;
        color: #fff !important;
        border-radius: 4px;
    }
}
.example-card{
    position: relative;
    width: 100%;
    padding: 15px;
    background: rgba(29, 102, 255, 0.05);
    border-radius: 15px;
    float: left;
    overflow: hidden;
    .coming-soon{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(242, 245, 251, 0.8);
        z-index: 999;
        h4{
            position: relative;
            font-size: 36px;
            top: 53%;
            font-weight: 600;
            color: #283A5D;
            background-color: rgba(237, 239, 244, 0.6);
            width: 100%;
            padding: 5px 0;
            text-align: center;
        }
    }
    &--top{
        @include position-relative;
        @include common-flex;
        flex-direction: column;
        height: 240px;
        .example-ribbon {
            width: 180px;
            padding: 10px;
            position: absolute;
            text-align: center;
            color: #f0f0f0;
            transform: rotate(-45deg);
            background: #FFC700;
            box-shadow: 4.2239px 4.2239px 28.1593px rgba(40, 58, 93, 0.05);
            font-size: 18px;
            font-weight: 700;
            color: #000;
            top: 1px;
            left: -65px;
            line-height: 1;
          }

        h3{
            padding-top:10px;
            font-size: 25px;
            font-weight: bold;
            text-align: center;
            span{
                font-weight: 400;
                font-style: italic;
            }
        }
        .circular-progress{
            width: 100px;
            height:100px;
            border-radius: 50%;
            border: solid 10px #C5C831;
        }
        svg{
            width: 100%;
            height: 100%;
        }
    }
    &--bottom{
        @include position-relative;
        padding: 0 15px;
        .white-card{
            @include position-relative;
            @include common-flex;
            padding: 25px;
            background-color: $color-white;
            margin-bottom: 15px;
            flex-direction: column;
            border-radius: 7px;

            h6{
                font-weight: 600;
                font-size: 21px;
                line-height: 1.2;
                color: #283A5D;
                text-align: center;
                padding: 6px 0;
            }
            .score-progress{
                @include position-relative;
                margin-top: 10px;
                &--row{
                    @include position-relative;
                    @include common-flex;
                    gap: 10px 10px;
                    margin-bottom: 5px;
                    .label{
                        width: 40%;
                        font-size: 16px;
                        color: #283A5D;
                    }
                    .score{
                        width: 50%;
                        .progress{
                            height: 8px !important;
                        }
                        .lemon .progress-bar{
                            background-color: #B2B607;
                        }
                        .green .progress-bar{
                            background-color: #88B607;
                        }
                    }
                    .value{
                        width: 10%;
                        font-size: 16px;
                        color: #283A5D;
                        text-align: right;
                    }
                }
            }
            .btn-link{
                font-size: 16px;
                padding: 10px 0 10px 0;
                color: $secondary-color;
                &:hover{
                    opacity: .8;
                    background-color: transparent;
                }
                &:focus{
                    background-color: transparent;
                    box-shadow: none;
                }
            }
        }
        h3{
            font-size: 26px;
            font-weight: 700;
            text-align: center;
            color: #283A5D;
            line-height: 1.2;
            margin-bottom: 12px;
            letter-spacing: -1px;
        }
        p{
            font-size: 17px;
            text-align: center;
            color: #283A5D;
        }
    }
}
.form-wrapper{
    display: flex;
    .form-radio{
        cursor: pointer;
        .form-label{
            cursor: pointer;
        }
    }
}
.logo-box{
    @include position-relative;
    height: 350px;
    @include common-flex;
    background: rgba(29, 102, 255, 0.05);
    img{
        max-width: 80%;
    }
}
.area-not-listed{
    padding: 60px 0 0 0 !important;
}
.done-page{
    padding: 30px 0;
    h4{
        color: $color-black;
        font-weight: 400 !important;
        margin-bottom: 25px;
       }
    .title-outer{
        @include position-relative;
        @include common-flex;
       flex-direction: column;
       margin-bottom: 15px;

    }
    .title{
        font-size: 30px;
        margin: 10px 0;
    }
    .hightlight-msg{
        @include position-relative;
        padding: 15px;
        background: rgba(29, 102, 255, 0.05);
        border-radius: 4px;
    }
    p {
        margin-bottom: 37px;
    }
}
.custom-pl{
    margin-left: 20px;
}
.test-question{
    color: #000 !important;
    margin: 30px 0 15px 0;
}
.test-option{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 8px 15px 9px 15px;
    margin-bottom: 15px;
    .form-check-input{
        margin-left: 0;
        margin-right: 5px;
    }
}

.fee-tracker{
    @include position-relative;
    display: flex;
    gap: 5px;
    .end-percentage, .start-percentage{
        font-weight: 400;
        font-size: 11px;
        color: #283A5D;
        width:25px;
        position: relative;
        top: 6px;
    }
    .tracker{
        width: calc(100% - 50px);
        &--middle{
            @include position-relative;
            @include common-flex;

        }
        &--bottom{
            @include position-relative;
            height: 10px;
           font-size: 8px;
           color: #283A5D;
           display: flex;
           justify-content: space-between;
           margin-top:3px;
           gap:4px;
           align-items: center;
           .divider{
            position: relative;
            width: 100%;
            float: left;
            &:after{
                position: absolute;
                content:'';
                width: 100%;
                height:1px;
                background-color: #CACACC;
                top: 0;
                left: 0;
            }
           }
        }
    }
}
.cities-heading{
    display: flex;
    justify-content: space-between;
}
.custom-checkbox {
    background-color: rgba(29, 102, 255, 0.05);
    float: left;
    padding: 7px 10px;
    border-radius: 10px;
    margin: 25px 0;
}



.form-single-confirm{
  background: rgba(29, 102, 255, 0.05);
  border-radius: 15px;
  padding: 35px 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
