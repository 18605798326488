$primary-color: #48C898;
$primary-light: rgba(72, 200, 152, .2);
$primary-extra-light: rgba(72, 200, 152, .1);
$secondary-color: #1D66FF;
$secondary-light: rgba(29, 102, 255, .05);
$btn-bg: #3DC593;

$color-white: #fff;
$color-black: #000;
$color-disabled: rgba(255, 255, 255, .5);
$light-orange: #FFEFEF;
$light-orange-2: #FFF5F5;
$light-black: #212223;
$color-dark: #141415;
$light-green: #719B7F;
$light-green-2: #81f3c9;
$white-opacity-9: rgba(255, 255, 255, .9);
$error-color: #FF4949;
$warning-color: #FF8E00;

$grey-color: #E1E1E1;
$grey-color-1: #878787;
$grey-color-2: #e7e7e7;
$grey-10: #535353;
$grey-20: #BCBCBC;
$grey-30: #666;

$black-light: rgba(92, 92, 92, .7);

$outline: rgba(0, 0, 0, .1);
$outline-dark: rgba(0, 0, 0, .3);
$outline-grey: #BCBCBC;
$outline-black: rgba(0, 0, 0, .5);

$primary-gradient: linear-gradient(4deg, rgba(85,236,179,1) 0%, rgba(72,200,152,1) 100%);

$semibold: 600;

