
  

.page-loader {
  width: 56px;
  height: 56px;
  border: 4px solid transparent;
  border-radius: 50%;
  position: relative;
  top: 50%;
  margin: 20vh auto;
  &:before{
    content: '';
    border: 4px solid rgba(0, 82, 236, 0.5);
    border-radius: 50%;
    width: 67.2px;
    height: 67.2px;
    position: absolute;
    top: -9.6px;
    left: -9.6px;
    animation: loader-scale 1s ease-out infinite;
    animation-delay: 1s;
    opacity: 0;
  }
  &:after{
    content: '';
    border: 4px solid #0052ec;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    position: absolute;
    top: -4px;
    left: -4px;
    animation: loader-scale 1s ease-out infinite;
    animation-delay: 0.5s;
  }
}
@keyframes loader-scale {
  0% {
    transform: scale(0);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 0; } }




    .loader-dual-ring {
      display: inline;
      width: 20px;
      height: 20px;
      position: relative;
      @include mobile-sm{
          width: 15px;
          height: 15px;
      }
      &:after {
        content: " ";
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid $color-white;
        border-color: $color-white transparent $color-white transparent;
        animation: loader-dual-ring 1.2s linear infinite;
        @include mobile-sm{
            width: 15px;
            height: 15px;
            border: 1px solid $color-white;
            border-color: $color-white transparent $color-white transparent;
        }
      }
    }
    @keyframes loader-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }