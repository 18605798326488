.service-pricing-table{
    border: 1px solid #EEEEEE;
    margin: 15px 0 35px 0;
    thead{
        tr{
            th{
                padding: 13px 27px;
                background-color: #F5F7F9;
                font-size: 16px;
                border-left: solid 1px #eee;
                @media(max-width:767.98px){
                    font-size: 14px;
                }
                &:first-child{
                    border-left: none;
                }
                &:last-child{
                    text-align: center;
                }
                @media(max-width:767.98px){
                    white-space:nowrap;
                }
            }
        }
    }
    tbody{
        tr{
            td{
                padding: 13px 27px;
                font-size: 16px;
                border-left: solid 1px #eee;
                @media(max-width:767.98px){
                    padding: 13px 13px;
                }
                &:first-child{
                    border-left: none;
                }
                &:last-child{
                    text-align: center;
                }
                .service{
                    @include position-relative;
                    &--name{
                        font-weight: 600;
                        font-size: 14px;
                        color: $color-black;
                    }
                    &--hour{
                        font-weight: 400;
                        font-size: 11px;
                        color: $color-black;
                    }
                }
                .price{
                    @include position-relative;
                    @include common-flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    &--item{
                        position: relative;
                        width: 50%;
                        float: left;

                        @media(max-width:1399.98px) {
                          width: 100%;
                          padding-top: 6px;
                        }
                        .label{
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 1;
                            color: rgba(0, 0, 0, 0.6);
                            white-space: nowrap;
                        }
                        .add-price{
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 1;
                            color: $secondary-color;
                            background: none;
                            border: none;
                            padding: 0;
                            &:hover{
                                opacity: .8;
                            }
                            &:focus{
                              box-shadow: none;
                            }
                        }
                        .added-price{
                            font-size: 22px;
                            font-weight: bold;
                        }
                        .amount{
                            font-weight: 400;
                            font-size: 15px;
                        }
                    }
                }
            }
            &:nth-child(even){
                background-color: #F5F7F9;
            }
        }
    }
    &.service-pricing-table--splitter{
        border: none !important;
       th, td{
            border: none !important;
            border-bottom-width: 0px !important;
        }
        th{
            border-left: solid 1px #eee !important;
            &:first-child{
                border-left: none !important;
            }
        }

        tbody{
            tr{
                &:nth-child(even){
                    background-color: #fff;
                }
                &:nth-child(odd){
                    background-color: #F5F7F9;
                }
                &:last-child{
                    border-bottom: solid 1px #eee;
                }
            }
            .data-tr{
                td{
                    border-left: solid 1px #eee !important;
                    &:last-child{
                        border-right: solid 1px #eee !important;
                    }
                }
            }
            .splitter-tr{
                background-color: #fff !important;
                border-top: solid 1px #eee;
                border-bottom: solid 1px #eee;
                td{
                    height: 10px !important;
                    padding: 0;
                    border-left: none !important;
                    border-right: none !important;
                }
            }
        }
    }
}
