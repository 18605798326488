.header-progress-bar{
    @include common-flex-jsb;
    @include mobile{
        justify-content: center;
    }
    &__module{
        width: 12.5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        text-align: center;
        @include mobile{
            display: none;
        }
        .icon{
            width: 58px;
            height: 58px;
            border-radius: 50%;
            border: 2px solid $color-white;
            @include common-flex;
            @include desktop{
                width: 52px;
                height: 52px;
            }
            svg path{
                fill: $color-white;
            }
        }
        p{
            font-size: 13px;
            color: $color-white;
            margin: 15px 5px 0;
            white-space: nowrap;
            @include desktop{
                font-size: 10px;
            }
            @include tablet{
                font-size: 9px;
            }
        }
        &:not(:first-of-type){
            .icon{
                position: relative;
                &::before{
                    content: "";
                    position: absolute;
                    width: 105px;
                    left: -107px;
                    height: 2px;
                    background: $color-white;
                    top: 50%;
                   
                    @include desktop-lg{
                        width: 84px;
                        left: -84px;
                    }
                    @include desktop{
                        width: 66px;
                        left: -68px;
                    }
                    @include tablet{
                        width: 35px;
                        left: -37px;
                    }
                }
            }
        }
        &.active{
            .icon{
                background: $primary-color;
                border: 2px solid $primary-color;
                &::before{
                    background: $primary-color;
                }
                svg path{
                    fill: $color-white;
                }
            }
            p{
                font-weight: 700;
            }
        }
        &.active-show{
            @include mobile{
                display: flex !important;
                .icon{
                    &::before{
                        display: none;
                    }
                }
            }
        }
    }
}
