@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: $color-black;
}
a{
    text-decoration: none;
    transition: all .5s;
}
ul{
    padding: 0;
    margin: 0;
    li{
        list-style: none;
    }
}

h1,
.h1 {
  @include h(1);
}

h2,
.h2 {
  @include h(2);
}

h3,
.h3 {
  @include h(3);
}

h4,
.h4 {
  @include h(4);
}

h5,
.h5 {
  @include h(5);
  a{
    font-size: 18px;
    color: $secondary-color;
    font-weight: 400;
    margin: 0 4px;
    &:hover{
      opacity: .8;
    }
  }
}

h6,
.h6 {
  @include h(6);
}

p{
  a{
    color: $secondary-color;
    &:hover{
      opacity: .8;
    }
  }
}
.custom-h5{
  font-size: 20px;
}
.sub-text{
  font-size: 16px;
  margin: 0;
}

.disable-pointer-events{
  pointer-events: none !important;
}

.react-toast-container{
  .Toastify__toast--error {
    background: #FF4949 !important;
  }
  .Toastify__toast--success {
    background: #48c898 !important;
  }
}


.info-icon-outer {
  cursor: pointer;
}