.main-header{
    @include position-relative;
    background: url(../../../public/Images/signup-hero-banner-02.jpg) no-repeat;
    background-size: cover;
    padding: 34px 0px 36px;
    min-height: 160px;
    &__outer{
        padding: 0;
        @include tablet{
            padding: 0;
        }
    }
    &__inner{
        @include common-flex-spacebetween;
        margin-bottom: 40px;
        @include mobile{
            margin-bottom: 10px;
        }
        img{
            max-width: 85px;
            @include mobile-sm{
                max-width: 50px;
            }
        }
    }
    .username{
        color: $color-white;
        font-size: 16px;
        margin-right: 5px;
        @include mobile-sm{
            font-size: 14px;
        }
    }
    .signout{
        font-size: 16px;
        color: $primary-color;
        @include mobile-sm{
            font-size: 14px;
        }
        &:hover{
            color: $primary-color;
        }
    }
}