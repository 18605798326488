.signup-modal{
    @media(min-width:768px){
        max-width:755px;
    }
    .modal-content{
        border-radius: 20px;
        .modal-header{
            border-bottom: none;
            padding: 25px 25px 0 25px;
            .modal-title{
                font-size: 20px;
            }
        }
        .modal-body{
            padding: 25px;
            .hightlight-box{
                padding: 15px;
                border-radius: 6px;
                background-color: #FFEFEF;
                display: flex;
                gap: 0 10px;
                justify-content: flex-start;
                svg{
                    flex-shrink: 0;
                    @include mobile-sm{
                        width: 18px;
                        height: 18px;
                        margin-top: 5px;
                    }
                }
                .question-wrapper{
                    width: 100%;
                }
                p{
                    font-size: 15px;
                    font-weight: 600;
                }
                .form-box-dark{
                    position: relative;
                    display: flex;
                    gap: 0 10px;
                    font-size: 16px;
                   label{
                    color: #000;
                    margin: 0;
                    line-height: 2;
                    position: absolute;
                    padding: 2px 20px 2px 27px;
                    cursor: pointer;
                   }
                    span{
                        font-weight: 600;
                    }
                    .form-check-box{
                        position: relative;
                        top: 3px;
                    }
                    .form-check-input{
                        width: 20px;
                        height: 20px;
                        border-color: #283A5D;
                        &:checked{
                            background-color: $primary-color;
                            border-color: $primary-color;
                        }
                        &:focus{
                            box-shadow: 0 0 0 0.25rem #48C89810;
                        }
                    }
                }
            }
        }
        .modal-footer{
            border-top: none;
            justify-content: center;
            padding-bottom: 25px;
        }
    }
}

.sla-agreement-modal{
    .modal-header{
        .modal-title{
            font-size: 26px;
            font-weight: 400;
        }
    }
    .modal-body{
        .sla-agreement-data{
            height: 80vh;
            overflow-y: auto;
            p{
                font-size: 15px;
            }
            ul{
                padding-left: 30px;
            }
            ul li {
                list-style: disc;
            }
        }
       
    }
}
.scoring-metrics-modal{
    .modal-header{
        .modal-title{
            font-size: 26px;
            font-weight: 400;
        }
    }
    .scoring-data{
        width: 100%;
        h4{
            font-size: 18px;
            font-weight: 700;
            color: #283A5D;
        }
        p{
            font-size: 15px;
            color: #283A5D;
            margin-bottom: 25px;
        }
    }
}



.service-pricing-data{
    .preferred-service{
        @include position-relative;
        padding: 13px 22px;
        background: rgba(29, 102, 255, 0.05);
        border: 1px solid #1D66FF;
        box-shadow: 0px 6px 14px rgba(15, 107, 250, 0.1);
        border-radius: 7px;
        margin-bottom: 24px;
        .service-name{
            font-weight: 600;
            font-size: 22.0576px;
            line-height: 35px;
            color: #000;
            margin: 0;
        }
        .hours{
            font-weight: 400;
            font-size: 17.3309px;
            color: #000;
            margin: 0;
        }
    }
    .service-info{
        @include position-relative;
        h4{
            font-weight: 600;
            font-size: 21.775px;
            color: #000;
        }
        p{
            font-weight: 400;
            font-size: 16.3312px;
            line-height: 22px;
            color: rgba(40, 58, 93, 0.7);
            margin-bottom: 24px;
        }
    }
    .input-outer{
        @include position-relative;
        @include common-flex-jsb;
        padding: 0 10px;
        background: #FFFFFF;
        border: 1.08875px solid rgba(40, 58, 93, 0.2);
        border-radius: 6.53249px;
        .form-control{
            width: 80px;
            border: none;
            padding: 0;
            box-shadow: none;
            color: #000;
        }
        .status{
            display: flex;
            align-items: center;
            font-size: 15px;
            gap: 0 5px;
            &.error{
                color: #FF4949;
            }
            .price-bubble{
                position: absolute;
                top: -30px;
                left: unset;
                right: -100px;
                padding: 5px 10px;
                background: #FFFFFF;
                box-shadow: 0px 4.50401px 18.0161px rgba(0, 0, 0, 0.15);
                border-radius: 4.50401px;
                text-align: center;
                display: flex;
                flex-direction: column;
                .your-price{
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 1;
                }
                small{
                    font-size: 11px;
                    line-height: 1;
                }
                &::after{
                    position: absolute;
                    content: '';
                    bottom:-7px;
                    left: 44%;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 7px solid #fff;
                    box-shadow: 0px 4.50401px 18.0161px rgba(0, 0, 0, 0.15);
                }
            }
        }
        
    }
    .pricing-chart{
        @include position-relative;
        height: 46px;
        background: #1D66FF10;
        border-radius: 6.53249px;
        margin-top: 22px;
        padding: 0 11px;
        gap: 0 10px;
        @include common-flex-jsb;
        .price{
            display: flex;
            flex-direction: column;
            font-weight: 600;
            font-size: 18px;
            line-height: 1;
            &.max{
                small{
                    font-weight: 400;
                    font-size: 9.83px;
                }
            }
        }
        .price-progress{
            width: calc(100% - 150px);
            .chart-base{
                @include position-relative;
                background: linear-gradient(90deg, #48C898 0%, #FFC700 50%, #FF0000 100%);
                border-radius: 7px;
                height: 13px;
                .chart-overlay{
                    position: relative;
                    height: 100%;
                    top: 0;
                    right: 0;
                    background-color: #ffffff99;
                    border-top-right-radius: 7px;
                    border-bottom-right-radius: 7px;
                    border-left: solid 2px #fff;
                    float: right;
                    &.completed{
                        border-bottom-left-radius: 7px;
                        border-top-left-radius: 7px;
                        border-left: none;
                    }
                    .price-bubble{
                        position: absolute;
                        top: -49px;
                        left: -37px;
                        padding: 5px 10px;
                        background: #FFFFFF;
                        box-shadow: 0px 4.50401px 18.0161px rgba(0, 0, 0, 0.15);
                        border-radius: 4.50401px;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        .your-price{
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 1;
                        }
                        small{
                            font-size: 11px;
                            line-height: 1;
                        }
                        &::after{
                            position: absolute;
                            content: '';
                            bottom:-7px;
                            left: 44%;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 7px solid #fff;
                            box-shadow: 0px 4.50401px 18.0161px rgba(0, 0, 0, 0.15);
                        }
                    }
                }

            }
        }
    }
    .pricing-label{
        font-size: 14px;
        color: rgba(40, 58, 93, 0.7);
    }
    .service-price-btn-outer{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 15px;
        margin: 30px 0;
    }
}

.make-a-wish-modal{
    max-width: 900px;
    padding: 30px 0;
    .modal-content{
        border-radius: 0;
        background: $color-white url(../../../public/Images/make-wish-bg.jpg) no-repeat 50%;
        background-size: cover;
        border-radius: 0;
        max-height: 100%;
        overflow: auto;
    }
    .modal-header {
        padding: 25px;
        border: 0;
        .modal-title {
            font-size: 24px;
            color: $color-black;
            @include mobile-sm{
                font-size: 20px;
            }
        }
    }
    .modal-body {
        padding: 15px 20px;
        max-height: unset;
        overflow: unset;
        h3{
            font-size: 22px;
            color: $color-black;
            @include mobile-sm{
                font-size: 18px;
            }
        }
        p {
            font-size: 18px;
            line-height: 1.6;
            color: $grey-10;
            padding: 20px 0 0;
            margin: 0;
            @include mobile-sm{
                padding: 10px 0 0;
                font-size: 15px;
            }
        }
    }
    .modal-footer {
        @include mobile-sm{
            padding: 10px 15px 30px;
        }
        .btn-green-solid-type{
            color: $color-white;
            background-color: $btn-bg;
            border: 1px solid $btn-bg;
            border-radius: 0;
            font-weight: 500;
            font-size: 16px;
            padding: 14px 50px;
            text-transform: uppercase;
            outline: none!important;
            @include mobile-sm{
                font-size: 14px;
                padding: 10px 30px;
            }
            &:active, &:hover, &:focus{
                background-color: transparent;
                border: 1px solid $btn-bg;
                color: $btn-bg;
            }
        }
    }
}